<template>
  <f7-block-title class="margin-top" :class="{ 'skeleton-text': isLoading }">
    <string-label v-if="article" :value="article.name" />
  </f7-block-title>
  <f7-card :class="{ 'skeleton-text': isLoading }">
    <f7-card-content>
      <f7-list media-list>
        <f7-list-item header="Company">
          <string-label :value="article?.company.name" />
        </f7-list-item>
        <f7-list-item header="Code">
          <string-label :value="article?.code" />
        </f7-list-item>
        <f7-list-item
          :accordion-item="useBarcodeAccordion && article.articleBarcodes.length > 1"
          header="Primary barcode"
        >
          <template #title>
            <string-label :value="article?.primaryBarcode" />
          </template>
          <template #after v-if="!useBarcodeAccordion">
            <font-awesome-icon
              :icon="['far', 'chevron-right']"
              @click="$emit('show-barcodes', true)"
            />
          </template>
          <barcode-accordion
            v-if="useBarcodeAccordion"
            :article-barcodes="article.articleBarcodes"
            :primary-barcode="article.primaryBarcode"
          />
        </f7-list-item>
        <f7-list-item header="Weight (gr.)">
          <string-label :value="article?.weight" fallback-value="0" />
        </f7-list-item>
        <f7-list-item header="Classifications" v-if="hasClassifications">
          <classifications-label :items="article?.classifications" />
        </f7-list-item>
      </f7-list>
    </f7-card-content>
  </f7-card>
</template>
<script lang="ts" setup>
import BarcodeAccordion from '@components/Article/BarcodeAccordion.vue'
import ClassificationsLabel from '@components/label/ClassificationsLabel.vue'
import StringLabel from '@components/label/StringLabel.vue'
import useArticle from '@composables/useArticle'
import { IArticle } from '@graphql/article/types'

withDefaults(
  defineProps<{
    article: IArticle
    isLoading?: boolean
    useBarcodeAccordion?: boolean
  }>(),
  {
    isLoading: false,
    useBarcodeAccordion: false
  }
)

defineEmits<{
  (e: 'show-barcodes', payload: boolean)
}>()

const { hasClassifications } = useArticle()
</script>

<template>
  <f7-page :page-content="false">
    <navigation v-slot="{ searchId }">
      <search-bar :search-id="searchId" expandable />
    </navigation>
    <f7-page-content>
      <template v-if="currentStockLevels">
        <f7-block-title class="margin-top" :class="{ 'skeleton-text': isLoading }">
          <string-label :value="currentArticle.name" />
        </f7-block-title>
        <f7-block-header class="margin-top" :class="{ 'skeleton-text': isLoading }">
          Stock
        </f7-block-header>
        <f7-card class="data-table" :class="{ 'skeleton-text': isLoading }">
          <f7-card-content v-if="!currentStockLevels || currentStockLevels.length === 0">
            No stock data available
          </f7-card-content>
          <stock-level-table v-else :items="currentStockLevels" @row-click="onStockLevelRowClick">
          </stock-level-table>
        </f7-card>
      </template>
    </f7-page-content>
    <f7-popup
      class="article-location-stock"
      :opened="popupOpened"
      @popup:closed="popupOpened = false"
    >
      <div v-if="activeStockLevelResult">
        <stock-popup
          :stock-level="activeStockLevelResult.stockLevel"
          :article="activeStockLevelResult.article"
          :location="activeStockLevelResult.location"
        />
      </div>
    </f7-popup>
  </f7-page>
</template>
<script lang="ts" setup>
import StringLabel from '@components/label/StringLabel.vue'
import Navigation from '@components/AppNavigation.vue'
import SearchBar from '@components/search/SearchBar.vue'
import StockLevelTable from '@components/StockLevelTable.vue'
import StockPopup from '@components/StockPopup.vue'
import { IArticle, IStockLevel, IStockLevelResult } from '@graphql/article/types'
import { computed, onMounted, ref } from 'vue'
import useStock from '@composables/useStock'

const props = defineProps<{
  item: IArticle
}>()

const { getStockLevels } = useStock()

const stockLevelResults = ref<IStockLevel[]>([])
const popupOpened = ref(false)
const isLoading = ref(false)
const activeStockLevelResult = ref<IStockLevelResult | null>(null)

const currentStockLevels = computed(() => stockLevelResults.value)
const currentArticle = computed(() => props.item)

const onStockLevelRowClick = (stockLevelResult: IStockLevelResult) => {
  if (!stockLevelResult) {
    return
  }

  activeStockLevelResult.value = stockLevelResult
  popupOpened.value = true
}

onMounted(async () => {
  isLoading.value = true
  // resolve stock for given articleId and/or locationId
  const response = await getStockLevels({ articleId: currentArticle.value.id })

  stockLevelResults.value = await response.data.data.stockLevel

  isLoading.value = false
})
</script>
<style lang="less">
.data-table th.label-cell,
.data-table td.label-cell {
  padding: 1rem;
}
</style>

import { MutationTree } from 'vuex'
import { IProjectRelocateState, IRelocateItem } from './types'

function findIndexByItem(collection: IRelocateItem[], item: IRelocateItem) {
  return collection
    .map((stateItem: IRelocateItem) => [stateItem.articleID, stateItem.locationID].join('-'))
    .indexOf(`${item.articleID}-${item.locationID}`)
}

const mutations: MutationTree<IProjectRelocateState> = {
  add(state, item: IRelocateItem): void {
    const index = findIndexByItem(state.items, item)

    if (index > -1) {
      const currentItem = state.items[index]
      item.qty = currentItem.qty + item.qty

      if (item.moveAll || item.qty === currentItem.qty) {
        state.items.splice(index, 1)
      } else {
        state.items[index] = item
      }
    } else {
      state.items.push(item)
    }
  },

  remove(state, item: IRelocateItem): void {
    const index = findIndexByItem(state.items, item)

    state.items.splice(index, 1)
  },

  update(state, item: IRelocateItem): void {
    const index = findIndexByItem(state.items, item)
    const currentItem = state.items[index]

    if (currentItem === undefined) {
      throw Error('Could not update, no item!')
    }

    if (item.moveAll || item.qty === currentItem.qty) {
      state.items.splice(index, 1)
    } else {
      item.qty = currentItem.qty - item.qty

      state.items[index] = item
    }
  },

  updateTransit(state, item: IRelocateItem) {
    const index = findIndexByItem(state.items, item)
    const currentItem = state.items[index]

    if (currentItem === undefined) {
      throw Error('Could not update, no item!')
    }

    if (item.moveAll) {
      state.items.slice(0).forEach((relocatable) => {
        if (relocatable.articleID === currentItem.articleID) {
          state.items.splice(state.items.indexOf(relocatable), 1)
        }
      })
    } else if (item.qty === currentItem.qty) {
      state.items.splice(index, 1)
    } else {
      item.qty = currentItem.qty - item.qty

      state.items[index] = item
    }
  },

  clear(state): void {
    state.items = []
  }
}

export default mutations

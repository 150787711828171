<template>
  <f7-fab :position="position" :fixed="fixed" @click="opened = true">
    <f7-icon :material="menuIcon" />
  </f7-fab>
  <f7-actions id="bottom-actions" :opened="opened" @actions:close="opened = false">
    <f7-actions-group>
      <f7-actions-button
        bold
        v-for="(action, key) in visibleActions"
        :key="key"
        @click="onClick(action)"
        v-html="action.name"
      />
      <f7-actions-button color="red" close>Cancel</f7-actions-button>
    </f7-actions-group>
  </f7-actions>
</template>
<script lang="ts" setup>
import { IAction } from '@components/actions'
import useAuthentication from '@composables/useAuthentication'
import { computed, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    actions: IAction[]
    fixed?: boolean
    morphTo?: string
    position?: string
  }>(),
  {
    actions: () => [],
    fixed: true,
    morphTo: undefined,
    position: 'center-bottom'
  }
)

const emits = defineEmits<{
  (e: 'action-clicked', action: IAction)
}>()

const { isGranted } = useAuthentication()

const opened = ref(false)

const menuIcon = computed(() => {
  if (props.actions.length === 1) {
    return props.actions[0].icon != undefined ? props.actions[0].icon : 'menu'
  }

  return 'menu'
})

const onClick = async (action: IAction) => {
  await emits('action-clicked', action)
}

const visibleActions = computed<IAction[]>(() =>
  props.actions?.filter((action) => isGranted(action.permissions ?? []))
)
</script>

import {
  faBarcode,
  faBarcodeScan,
  faBox,
  faBoxCheck,
  faBoxesStacked,
  faBoxFull,
  faBoxOpen,
  faBoxOpenFull,
  faCartFlatbedBoxes,
  faCartPlus,
  faCheck,
  faCheckCircle,
  faChevronRight,
  faCircle,
  faClipboardCheck,
  faConveyorBeltBoxes,
  faConveyorBeltEmpty,
  faEmptySet,
  faExclamation,
  faExternalLink,
  faHandHoldingBox,
  faHandPaper,
  faInbox,
  faInputNumeric,
  faLaughBeam,
  faList,
  faLocationCrosshairs,
  faMapMarker,
  faMapMarkerAlt,
  faMapMarkerSlash,
  faMapMarkerSmile,
  faMobile,
  faNfcSignal,
  faPalletBoxes,
  faPersonCarry,
  faPersonDollyEmpty,
  faPrint,
  faSignOut,
  faSlidersH,
  faTrash,
  faTruck,
  faUser,
  faUserCircle,
  IconDefinition
} from '@fortawesome/pro-regular-svg-icons'
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'

export const icons: IconDefinition[] = [
  faBarcode,
  faPersonCarry,
  faInbox,
  faList,
  faBox,
  faBoxCheck,
  faBoxOpenFull,
  faBoxOpen,
  faCheck,
  faCheckCircle,
  faExclamation,
  faExternalLink,
  faPrint,
  faBarcodeScan,
  faBoxFull,
  faMapMarker,
  faSlidersH,
  faSignOut,
  faMapMarkerSlash,
  faMapMarkerAlt,
  faMapMarkerSmile,
  faCircle,
  faTruck,
  faUser,
  faClipboardCheck,
  faEmptySet,
  faUserCircle,
  faLaughBeam,
  faHandPaper,
  faHandHoldingBox,
  faChevronRight,
  faInputNumeric,
  faBoxesStacked,
  faSpinnerThird,
  faPalletBoxes,
  faTrash,
  faNfcSignal,
  faConveyorBeltBoxes,
  faConveyorBeltEmpty,
  faCartFlatbedBoxes,
  faPersonDollyEmpty,
  faCartPlus,
  faLocationCrosshairs,
  faMobile
]

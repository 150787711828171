import stockApi from '@graphql/stock'
import { IGetStockLevelRequest } from '@graphql/stock/types'
import { IRelocateActionItem } from '@store/modules/projects/relocate/types'
import { ActionTree } from 'vuex'
import { IRootState } from '../../types'
import { IStockState, IStockItem } from './types'

const actions: ActionTree<IStockState, IRootState> = {
  async stockCount(context, stockItem: IStockItem) {
    return await stockApi.count(stockItem)
  },

  async stockRelocate(context, relocateItem: IRelocateActionItem) {
    const { item, locationID } = relocateItem

    return await stockApi.relocate({
      articleId: item.articleID,
      fromLocationId: item.locationID,
      toLocationId: locationID,
      moveAll: item.moveAll,
      qty: item.qty
    })
  },

  async getStockLevels(context, request: IGetStockLevelRequest) {
    return await stockApi.getStockLevels(request)
  }
}

export default actions

<template>
  <f7-accordion-content>
    <f7-list>
      <f7-list-item
        v-for="articleBarcode in nonPrimaryBarcodes"
        :key="articleBarcode.id"
        :header="articleBarcode.type"
        :title="articleBarcode.content"
      />
    </f7-list>
  </f7-accordion-content>
</template>
<script lang="ts" setup>
import { IArticleBarcode } from '@graphql/article/types'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    articleBarcodes: IArticleBarcode[]
    primaryBarcode?: string
  }>(),
  {
    primaryBarcode: ''
  }
)

const nonPrimaryBarcodes = computed<IArticleBarcode[]>(() =>
  props.articleBarcodes.filter((a) => a.content !== props.primaryBarcode)
)
</script>

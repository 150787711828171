<template>
  <f7-page :page-content="false">
    <navigation show-search-icon v-slot="{ searchId }">
      <search-bar :search-id="searchId" expandable />
    </navigation>

    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#article-detail" tab-link-active> Detail </f7-link>
      <f7-link tab-link="#article-barcodes"> Barcodes </f7-link>
    </f7-toolbar>

    <f7-tabs>
      <f7-tab id="article-detail" class="page-content" tab-active>
        <template v-if="article">
          <article-information-card
            :article="article"
            :is-loading="isLoading"
            @show-barcodes="onShowBarcodesTab"
          />
          <article-stock-card :article="article" :is-loading="isLoading" />
        </template>
      </f7-tab>
      <f7-tab id="article-barcodes" class="page-content">
        <template v-if="article">
          <article-barcode-table :article="article" :is-loading="isLoading" />
        </template>
      </f7-tab>
    </f7-tabs>

    <app-actions :actions="actions" @action-clicked="onActionClick" :fixed="true" />
  </f7-page>
</template>
<script lang="ts" setup>
import { IAction } from '@components/actions'
import AppActions from '@components/AppActions.vue'
import ArticleBarcodeTable from '@components/Article/BarcodeTable.vue'
import ArticleInformationCard from '@components/Article/InformationCard.vue'
import ArticleStockCard from '@components/Article/StockCard.vue'
import Navigation from '@components/AppNavigation.vue'
import SearchBar from '@components/search/SearchBar.vue'
import useAction from '@composables/useAction'
import useArticle from '@composables/useArticle'
import useLoading from '@composables/useLoading'
import { IArticle } from '@graphql/article/types'
import { IArticleLocation, ILocation } from '@graphql/location/types'
import { TypeName } from '@graphql/search/types'
import { SearchStrategy } from '@services/search/search'
import { getPhysicalLocations } from '@store/modules/article/helper'
import { f7 } from 'framework7-vue'
import { computed, onBeforeMount, ref } from 'vue'
import { ID } from '@graphql/types'

const props = defineProps<{
  id: ID
}>()

const { getArticle, articleLocations } = useArticle()
const { isLoading, withAsyncLoading } = useLoading()
const { onActionClick } = useAction()

const article = ref<IArticle | undefined>()
const actions = ref<IAction[]>([])
const locationToLoadThreshold = ref<number>(4)

const locations = computed<ILocation[]>(() =>
  articleLocations.value.map((articleLocation) => articleLocation.location)
)
const physicalLocations = computed<ILocation[]>(() => getPhysicalLocations(locations.value))

const resolvePhysicalLocations = async (): Promise<ILocation[]> => {
  if (locations.value.length < locationToLoadThreshold.value) {
    return physicalLocations.value
  }

  const response = await getArticle({
    id: (article.value as IArticle).id,
    articleLocationLimit: null
  })
  const loadedLocations = response.articleLocation.map((al: IArticleLocation) => al.location)

  return getPhysicalLocations(loadedLocations)
}

onBeforeMount(async () => {
  await withAsyncLoading(async () => {
    article.value = await getArticle({
      id: props.id,
      articleLocationLimit: locationToLoadThreshold.value
    })
  })

  actions.value.push({
    name: 'Count',
    href: '/scan-input/',
    props: {
      items: physicalLocations.value,
      searchType: TypeName.Location,
      searchStrategy: SearchStrategy.ScanInput,
      successUrl: '/count/',
      itemsCallback: resolvePhysicalLocations
    }
  })

  if (physicalLocations.value.length > 0) {
    actions.value.push({
      name: 'Relocate',
      href: '/scan-input/',
      props: {
        items: getPhysicalLocations(locations.value),
        searchType: TypeName.Location,
        searchStrategy: SearchStrategy.ScanInput,
        successUrl: '/remove/',
        restricted: true,
        itemsCallback: resolvePhysicalLocations
      }
    })
  }
})

const onShowBarcodesTab = async () => {
  await f7.tab.show('#article-barcodes')
}
</script>
<style scoped lang="less">
.actions-header {
  margin: 0 0 5px 0;
}
</style>
